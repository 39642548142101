import { IPredefinedTheme } from "../../Themes";
import img1 from "./screenshot.png";

export const Theme: IPredefinedTheme = {
  name: "Discord-like",
  description: "Discord inspired theme",
  credit: "Thermite",
  reference: "https://discord.com/channels/415207508303544321/921991895230611466/924305252017143818",
  screenshot: img1,
  colors: {
    primarylight: "#7389DC",
    primary: "#7389DC",
    primarydark: "#5964F1",
    successlight: "#00CC00",
    success: "#20DF20",
    successdark: "#0CB80C",
    errorlight: "#EA5558",
    error: "#EC4145",
    errordark: "#E82528",
    secondarylight: "#C3C3C3",
    secondary: "#9C9C9C",
    secondarydark: "#4E4E4E",
    warninglight: "#ff0",
    warning: "#cc0",
    warningdark: "#990",
    infolight: "#69f",
    info: "#36c",
    infodark: "#1C4FB3",
    welllight: "#999999",
    well: "#35383C",
    white: "#FFFFFF",
    black: "#202225",
    hp: "#FF5656",
    money: "#43FF43",
    hack: "#FFAB3D",
    combat: "#8A90FD",
    cha: "#FF51D9",
    int: "#6495ed",
    rep: "#FFFF30",
    disabled: "#474B51",
    backgroundprimary: "#2F3136",
    backgroundsecondary: "#35393E",
    button: "#333",
    maplocation: "#ffffff",
    bnlvl0: "#ffff00",
    bnlvl1: "#ff0000",
    bnlvl2: "#48d1cc",
    bnlvl3: "#0000ff",
  },
};
