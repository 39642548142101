import { IPredefinedTheme } from "../../Themes";
import img1 from "./screenshot.png";

export const Theme: IPredefinedTheme = {
  name: "Smooth Green",
  description: "A nice green theme that doesn't hurt your eyes.",
  credit: "Swidt",
  reference: "https://discord.com/channels/415207508303544321/921991895230611466/922243957986033725",
  screenshot: img1,
  colors: {
    primarylight: "#E0E0BC",
    primary: "#B0D9A3",
    primarydark: "#B8B89C",
    successlight: "#00F000",
    success: "#6BC16B",
    successdark: "#00B400",
    errorlight: "#F00000",
    error: "#3D713D",
    errordark: "#A00000",
    secondarylight: "#B4AEAE",
    secondary: "#8FAF85",
    secondarydark: "#787272",
    warninglight: "#F0F000",
    warning: "#38F100",
    warningdark: "#A0A000",
    infolight: "#69f",
    info: "#36c",
    infodark: "#039",
    welllight: "#444",
    well: "#2F3C2B",
    white: "#fff",
    black: "#1E1E1E",
    hp: "#dd3434",
    money: "#4AA52E",
    hack: "#adff2f",
    combat: "#faffdf",
    cha: "#a671d1",
    int: "#6495ed",
    rep: "#35A135",
    disabled: "#66cfbc",
    backgroundprimary: "#1E1E1E",
    backgroundsecondary: "#252525",
    button: "#2F3C2B",
    maplocation: "#ffffff",
    bnlvl0: "#ffff00",
    bnlvl1: "#ff0000",
    bnlvl2: "#48d1cc",
    bnlvl3: "#0000ff",
  },
};
