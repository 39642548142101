export enum CompletedProgramName {
  nuke = "NUKE.exe",
  bruteSsh = "BruteSSH.exe",
  ftpCrack = "FTPCrack.exe",
  relaySmtp = "relaySMTP.exe",
  httpWorm = "HTTPWorm.exe",
  sqlInject = "SQLInject.exe",
  deepScan1 = "DeepscanV1.exe",
  deepScan2 = "DeepscanV2.exe",
  serverProfiler = "ServerProfiler.exe",
  autoLink = "AutoLink.exe",
  formulas = "Formulas.exe",
  bitFlume = "b1t_flum3.exe",
  flight = "fl1ght.exe",
}
