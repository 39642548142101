import { IPredefinedTheme } from "../../Themes";
import img1 from "./screenshot.png";

export const Theme: IPredefinedTheme = {
  name: "Zerenity",
  description: "Calm purple and grey theme with some bright colors subtly popping up.",
  credit: "Zelow",
  reference: "https://discord.com/channels/415207508303544321/921991895230611466/1137934426832130168",
  screenshot: img1,
  colors: {
    primarylight: "#9B9BFF",
    primary: "#9B9BFF",
    primarydark: "#7373D7",
    successlight: "#32FF32",
    success: "#00FF00",
    successdark: "#00CD00",
    errorlight: "#FF3232",
    error: "#FF0000",
    errordark: "#CD0000",
    secondarylight: "#9B9BE1",
    secondary: "#9B9BB9",
    secondarydark: "#373755",
    warninglight: "#FFFF32",
    warning: "#FFFF00",
    warningdark: "#CDCD00",
    infolight: "#5A9BFF",
    info: "#3C7DFF",
    infodark: "#2869CD",
    welllight: "#444",
    well: "#111111",
    white: "#FFFFFF",
    black: "#000000",
    hp: "#FF1B1B",
    money: "#FFEE33",
    hack: "#00FF00",
    combat: "#F2FFFC",
    cha: "#893BC8",
    int: "#3179FF",
    rep: "#EDDEFF",
    disabled: "#515164",
    backgroundprimary: "#191919",
    backgroundsecondary: "#111011",
    button: "#232337",
    maplocation: "#ffffff",
    bnlvl0: "#ffff00",
    bnlvl1: "#ff0000",
    bnlvl2: "#48d1cc",
    bnlvl3: "#0000ff",
  },
};
