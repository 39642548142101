/*
The game cannot block every possible exploit. Especially since one of them is
that you can just edit your save file, and that's impractical to prevent.

So instead, we have "Source-File -1" ("negative one"). It is not obtained by destroying a
BitNode, but instead it is awarded when the player finds innovative ways to break
the game. This serves 2 purposes - [1] the developers don't have to spend time
trying to implement anti-cheat measures and [2] finding ways to break a
hacking game is very much a learning experience in the spirit of the game.
Source-File minus 1 is extremely weak because it can be fully levelled up quickly.
*/

// Exploits don't need an enum helper so they are not in an enums file
export enum Exploit {
  Bypass = "Bypass",
  PrototypeTampering = "PrototypeTampering",
  Unclickable = "Unclickable",
  UndocumentedFunctionCall = "UndocumentedFunctionCall",
  TimeCompression = "TimeCompression",
  RealityAlteration = "RealityAlteration",
  N00dles = "N00dles",
  YoureNotMeantToAccessThis = "YoureNotMeantToAccessThis",
  TrueRecursion = "TrueRecursion",
  INeedARainbow = "INeedARainbow",
  // To the players reading this:
  // Yes, you're supposed to gain the EditSaveFile exploit by editing your real save file.
  // TBH, you could gain them all the same way, but that is not our challenge to you.
  // You are the captain of your own ship.
  EditSaveFile = "EditSaveFile",
}

export const ExploitDescription: Record<Exploit, string> = {
  [Exploit.Bypass]: "by circumventing the ram cost of document.",
  [Exploit.EditSaveFile]: "by editing your save file.",
  [Exploit.PrototypeTampering]: "by tampering with Numbers prototype.",
  [Exploit.TimeCompression]: "by compressing time.",
  [Exploit.Unclickable]: "by clicking the unclickable.",
  [Exploit.UndocumentedFunctionCall]: "by looking beyond the documentation.",
  [Exploit.RealityAlteration]: "by altering reality to suit your whims.",
  [Exploit.N00dles]: "by harnessing the power of the n00dles.",
  [Exploit.YoureNotMeantToAccessThis]: "by accessing the dev menu.",
  [Exploit.TrueRecursion]: "by truly recursing.",
  [Exploit.INeedARainbow]: "by using the power of the rainbow.",
};

// Needed in case player edits save file poorly
export function sanitizeExploits(exploits: Exploit[]): Exploit[] {
  exploits = exploits.filter((e: Exploit) => Object.values(Exploit).includes(e));
  return [...new Set(exploits)];
}
