export enum AugmentationName {
  NeuroFluxGovernor = "NeuroFlux Governor",
  Targeting1 = "Augmented Targeting I",
  Targeting2 = "Augmented Targeting II",
  Targeting3 = "Augmented Targeting III",
  SyntheticHeart = "Synthetic Heart",
  SynfibrilMuscle = "Synfibril Muscle",
  CombatRib1 = "Combat Rib I",
  CombatRib2 = "Combat Rib II",
  CombatRib3 = "Combat Rib III",
  NanofiberWeave = "Nanofiber Weave",
  SubdermalArmor = "NEMEAN Subdermal Weave",
  WiredReflexes = "Wired Reflexes",
  GrapheneBoneLacings = "Graphene Bone Lacings",
  BionicSpine = "Bionic Spine",
  GrapheneBionicSpine = "Graphene Bionic Spine Upgrade",
  BionicLegs = "Bionic Legs",
  GrapheneBionicLegs = "Graphene Bionic Legs Upgrade",
  SpeechProcessor = "Speech Processor Implant",
  TITN41Injection = "TITN-41 Gene-Modification Injection",
  EnhancedSocialInteractionImplant = "Enhanced Social Interaction Implant",
  BitWire = "BitWire",
  ArtificialBioNeuralNetwork = "Artificial Bio-neural Network Implant",
  ArtificialSynapticPotentiation = "Artificial Synaptic Potentiation",
  EnhancedMyelinSheathing = "Enhanced Myelin Sheathing",
  SynapticEnhancement = "Synaptic Enhancement Implant",
  NeuralRetentionEnhancement = "Neural-Retention Enhancement",
  DataJack = "DataJack",
  ENM = "Embedded Netburner Module",
  ENMCore = "Embedded Netburner Module Core Implant",
  ENMCoreV2 = "Embedded Netburner Module Core V2 Upgrade",
  ENMCoreV3 = "Embedded Netburner Module Core V3 Upgrade",
  ENMAnalyzeEngine = "Embedded Netburner Module Analyze Engine",
  ENMDMA = "Embedded Netburner Module Direct Memory Access Upgrade",
  Neuralstimulator = "Neuralstimulator",
  NeuralAccelerator = "Neural Accelerator",
  CranialSignalProcessorsG1 = "Cranial Signal Processors - Gen I",
  CranialSignalProcessorsG2 = "Cranial Signal Processors - Gen II",
  CranialSignalProcessorsG3 = "Cranial Signal Processors - Gen III",
  CranialSignalProcessorsG4 = "Cranial Signal Processors - Gen IV",
  CranialSignalProcessorsG5 = "Cranial Signal Processors - Gen V",
  NeuronalDensification = "Neuronal Densification",
  NeuroreceptorManager = "Neuroreceptor Management Implant",
  NuoptimalInjectorImplant = "Nuoptimal Nootropic Injector Implant",
  SpeechEnhancement = "Speech Enhancement",
  FocusWire = "FocusWire",
  PCDNI = "PC Direct-Neural Interface",
  PCDNIOptimizer = "PC Direct-Neural Interface Optimization Submodule",
  PCDNINeuralNetwork = "PC Direct-Neural Interface NeuroNet Injector",
  PCMatrix = "PCMatrix",
  ADRPheromone1 = "ADR-V1 Pheromone Gene",
  ADRPheromone2 = "ADR-V2 Pheromone Gene",
  ShadowsSimulacrum = "The Shadow's Simulacrum",
  HacknetNodeCPUUpload = "Hacknet Node CPU Architecture Neural-Upload",
  HacknetNodeCacheUpload = "Hacknet Node Cache Architecture Neural-Upload",
  HacknetNodeNICUpload = "Hacknet Node NIC Architecture Neural-Upload",
  HacknetNodeKernelDNI = "Hacknet Node Kernel Direct-Neural Interface",
  HacknetNodeCoreDNI = "Hacknet Node Core Direct-Neural Interface",
  Neurotrainer1 = "Neurotrainer I",
  Neurotrainer2 = "Neurotrainer II",
  Neurotrainer3 = "Neurotrainer III",
  Hypersight = "HyperSight Corneal Implant",
  LuminCloaking1 = "LuminCloaking-V1 Skin Implant",
  LuminCloaking2 = "LuminCloaking-V2 Skin Implant",
  HemoRecirculator = "HemoRecirculator",
  SmartSonar = "SmartSonar Implant",
  PowerRecirculator = "Power Recirculation Core",
  QLink = "QLink",
  TheRedPill = "The Red Pill",
  SPTN97 = "SPTN-97 Gene Modification",
  HiveMind = "ECorp HVMind Implant",
  CordiARCReactor = "CordiARC Fusion Reactor",
  SmartJaw = "SmartJaw",
  Neotra = "Neotra",
  Xanipher = "Xanipher",
  nextSENS = "nextSENS Gene Modification",
  OmniTekInfoLoad = "OmniTek InfoLoad",
  PhotosyntheticCells = "Photosynthetic Cells",
  Neurolink = "BitRunners Neurolink",
  TheBlackHand = "The Black Hand",
  UnstableCircadianModulator = "Unstable Circadian Modulator",
  CRTX42AA = "CRTX42-AA Gene Modification",
  Neuregen = "Neuregen Gene Modification",
  CashRoot = "CashRoot Starter Kit",
  NutriGen = "NutriGen Implant",
  INFRARet = "INFRARET Enhancement",
  DermaForce = "DermaForce Particle Barrier",
  GrapheneBrachiBlades = "Graphene BrachiBlades Upgrade",
  GrapheneBionicArms = "Graphene Bionic Arms Upgrade",
  BrachiBlades = "BrachiBlades",
  BionicArms = "Bionic Arms",
  SNA = "Social Negotiation Assistant (S.N.A)",
  CongruityImplant = "violet Congruity Implant",
  HydroflameLeftArm = "Hydroflame Left Arm",
  BigDsBigBrain = "BigD's Big ... Brain",
  ZOE = "Z.O.Ë.",
  // UnnamedAug2 = "UnnamedAug2",

  // Bladeburner augs
  EsperEyewear = "EsperTech Bladeburner Eyewear",
  EMS4Recombination = "EMS-4 Recombination",
  OrionShoulder = "ORION-MKIV Shoulder",
  HyperionV1 = "Hyperion Plasma Cannon V1",
  HyperionV2 = "Hyperion Plasma Cannon V2",
  GolemSerum = "GOLEM Serum",
  VangelisVirus = "Vangelis Virus",
  VangelisVirus3 = "Vangelis Virus 3.0",
  INTERLINKED = "I.N.T.E.R.L.I.N.K.E.D",
  BladeRunner = "Blade's Runners",
  BladeArmor = "BLADE-51b Tesla Armor",
  BladeArmorPowerCells = "BLADE-51b Tesla Armor: Power Cells Upgrade",
  BladeArmorEnergyShielding = "BLADE-51b Tesla Armor: Energy Shielding Upgrade",
  BladeArmorUnibeam = "BLADE-51b Tesla Armor: Unibeam Upgrade",
  BladeArmorOmnibeam = "BLADE-51b Tesla Armor: Omnibeam Upgrade",
  BladeArmorIPU = "BLADE-51b Tesla Armor: IPU Upgrade",
  BladesSimulacrum = "The Blade's Simulacrum",

  StaneksGift1 = "Stanek's Gift - Genesis",
  StaneksGift2 = "Stanek's Gift - Awakening",
  StaneksGift3 = "Stanek's Gift - Serenity",

  // Infiltrators MiniGames
  MightOfAres = "SoA - Might of Ares", // slash
  WisdomOfAthena = "SoA - Wisdom of Athena", // bracket
  TrickeryOfHermes = "SoA - Trickery of Hermes", // cheatcode
  BeautyOfAphrodite = "SoA - Beauty of Aphrodite", // bribe
  ChaosOfDionysus = "SoA - Chaos of Dionysus", // reverse
  FloodOfPoseidon = "SoA - Flood of Poseidon", // hex
  HuntOfArtemis = "SoA - Hunt of Artemis", // mine
  KnowledgeOfApollo = "SoA - Knowledge of Apollo", // wire
  WKSharmonizer = "SoA - phyzical WKS harmonizer",
}
