import { IPredefinedTheme } from "../../Themes";
import img1 from "./screenshot.png";

export const Theme: IPredefinedTheme = {
  name: "Muted Gold & Blue",
  description: "Muted gold with blue accents.",
  credit: "Sloth",
  reference: "https://discord.com/channels/415207508303544321/921991895230611466/924672660758208563",
  screenshot: img1,
  colors: {
    primarylight: "#E3B54A",
    primary: "#CAA243",
    primarydark: "#7E6937",
    successlight: "#82FF82",
    success: "#6FDA6F",
    successdark: "#64C364",
    errorlight: "#FD5555",
    error: "#D84A4A",
    errordark: "#AC3939",
    secondarylight: "#D8D0B8",
    secondary: "#B1AA95",
    secondarydark: "#736E5E",
    warninglight: "#ff0",
    warning: "#cc0",
    warningdark: "#990",
    infolight: "#69f",
    info: "#36c",
    infodark: "#039",
    welllight: "#444",
    well: "#111111",
    white: "#fff",
    black: "#070300",
    hp: "#dd3434",
    money: "#ffd700",
    hack: "#adff2f",
    combat: "#faffdf",
    cha: "#a671d1",
    int: "#6495ed",
    rep: "#faffdf",
    disabled: "#66cfbc",
    backgroundprimary: "#0A0A0E",
    backgroundsecondary: "#0E0E10",
    button: "#222222",
    maplocation: "#ffffff",
    bnlvl0: "#ffff00",
    bnlvl1: "#ff0000",
    bnlvl2: "#48d1cc",
    bnlvl3: "#0000ff",
  },
};
