import { Player } from "@player";
import { Exploit } from "./Exploit";

function tampering(): void {
  if (Player.exploits.includes(Exploit.PrototypeTampering)) return;
  // Tampering
  const a = 55;
  setInterval(function () {
    if (a.toExponential() !== "5.5e+1") {
      Player.giveExploit(Exploit.PrototypeTampering);
    }
  }, 15 * 60 * 1000); // 15 minutes
}

function timeCompression(): void {
  const timer = 1000 * 15;
  if (Player.exploits.includes(Exploit.TimeCompression)) return;
  // Time compression
  let last = performance.now();
  function minute(): void {
    const now = performance.now();
    if (now - last < 500) {
      // time has been compressed.
      Player.giveExploit(Exploit.TimeCompression);
      return;
    }
    last = now;
    window.setTimeout(minute, timer);
  }
  window.setTimeout(minute, timer);
}

export function startExploits(): void {
  tampering();
  timeCompression();
}
